.loading {
  display: grid;
  place-items: center;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.loading__icon {
  font-size: 2rem;
  width: 100%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}