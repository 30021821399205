.product-card {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}

.product-card:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  > .button__add-cart {
    display: flex;
  }
}

.product__image {
  width: 100%;
  height: auto;
}

.card-image {
  width: 100%;
}

.card__infos {
  padding: 20px;
  border-top: 1px solid #ddd;
}

.card__price {
  font-size: 30px;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}

.card__title {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 1.5;
  font-weight: 500;
}

.button__add-cart {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  margin: 12px 15px;
  color: #0c5dd6;
  display: none;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  cursor: pointer;
}
