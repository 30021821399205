.cart-item {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

.cart-item-image {
  width: 70px;
}

.cart-item-title {
  font-size: 0.85rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.cart-item-price {
  font-size: 25px;
  font-weight: 500;
}

.cart-item-content {
  padding: 0 35px 0 10px;
}

.button__remove-item {
  position: absolute;
  top: 0;
  right: 0;
  color: #d83232;
  font-size: 1.4rem;
  border: none;
  background: none;
  cursor: pointer;
}