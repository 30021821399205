.search-results {
  padding-top: 20px;
}

.search-results__term {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}

.search-results__total {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.30;
}