.header {
  background-color: #fff159;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
}